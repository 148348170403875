import { Helmet } from "react-helmet"
import React from "react"
import * as styles from "../styles/roadmap/roadmap.module.css"

import Hero from "../components/NormalPageHero"
import LatestUpdatesBackground from "../assets/ZendooFooter.png"
import Layout from "../templates/Layout"
import NewsletterForm from "../components/NewsletterForm"
import RoadmapContent from "../components/Horizen2.0/Roadmap"
import ContentContainer from "../templates/ContentContainer"

import roadmapData from "../components/Horizen2.0/roadmapData"

const Roadmap = () => {
    return (
        <Layout>
            <div className="bg-horizen-hp-bg">
                <Helmet>
                    <title>The Horizen Roadmap</title>
                    <meta property="og:title" content="The Horizen Roadmap" />
                    <meta name="twitter:title" content="The Horizen Roadmap" />
                    <meta name="theme-color" content="#041742" />
                    <meta
                        name="description"
                        content="View the Horizen roadmap to learn more about the proof verification network for fast and affordable settlement."
                    />
                    <meta
                        property="og:description"
                        content="View the Horizen roadmap to learn more about the proof verification network for fast and affordable settlement."
                    />
                    <meta
                        name="twitter:description"
                        content="View the Horizen roadmap to learn more about the proof verification network for fast and affordable settlement."
                    />
                    <meta
                        property="og:image"
                        content="https://www.horizen.io/assets/images/meta/roadmap.jpg"
                    />
                    <meta
                        name="twitter:image"
                        content="https://www.horizen.io/assets/images/meta/roadmap.jpg"
                    />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta
                        name="keywords"
                        content="horizen roadmap, zen roadmap, zencash roadmap, zen cash roadmap"
                    />
                    <link
                        rel="canonical"
                        href="https://www.horizen.io/roadmap/"
                    />
                    <meta
                        property="og:url"
                        content="https://www.horizen.io/roadmap/"
                    />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="Horizen" />
                    <meta name="twitter:site" content="@horizenglobal" />
                    <meta name="twitter:creator" content="@horizenglobal" />
                </Helmet>
                <div
                    className={`pt-[9rem] md:pt-[calc(4rem+150px)] ${styles.roadmapHero}`}
                >
                    <Hero titleKey="roadmap.hero.heading" />
                </div>
                <div className=" text-white">
                    <ContentContainer>
                        <RoadmapContent roadmapContent={roadmapData} />
                    </ContentContainer>
                </div>

                <div
                    className="bg-contain bg-no-repeat"
                    style={{
                        backgroundImage: `url(${LatestUpdatesBackground})`,
                        backgroundPositionY: "bottom",
                    }}
                >
                    <div className="pt-24 lg:pt-56">
                        <NewsletterForm />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Roadmap
